import React, { FC, PropsWithChildren, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PermissionAccess } from '@symfa-inc/providence-types';
import { canBeEditable } from '@core/utils/methods';
import { ModalMainTypes } from '@models/enums';
import { JurisdictionDetailsPermittingType } from '@models/interfaces';
import { PermittingActions } from '@store/actions';
import { PermittingSelectors } from '@store/selectors';
import { CustomTable } from '../../components/custom-table';
import { DeleteModal } from '../../components/delete-modal';
import { PrimaryButton } from '../../modules/ui-kit/primary-button';
import { getPermitTypeTableColumns } from './helpers';
import { PermitTypeTableModal } from './modals';
import { DEFAULT_VALUES, PermitTypeTableProps } from './models';

import './styles.scss';

export const PermitTypeTable: FC<PermitTypeTableProps> = ({
  isEditing,
  permittingId,
  tableAccess = PermissionAccess.ReadOnly,
  showPermitExpireDate,
}: PropsWithChildren<PermitTypeTableProps>) => {
  const dispatch = useDispatch();

  const [formValue, setFormValue] =
    useState<JurisdictionDetailsPermittingType>(DEFAULT_VALUES);
  const [visibleModal, setVisibleModal] = useState<boolean>(false);
  const [deleteModalVisible, setDeleteModalVisible] = useState<boolean>(false);
  const [modalType, setModalType] = useState<ModalMainTypes>(
    ModalMainTypes.Add,
  );

  const { currentPermitTypeTableData } = useSelector(
    PermittingSelectors.getPermitTypeTableData(permittingId),
  );

  const openAddModal = (): void => {
    setFormValue(DEFAULT_VALUES);
    setVisibleModal(!visibleModal);
    setModalType(ModalMainTypes.Add);
  };

  const selectRowForEdit = (
    permittingType: JurisdictionDetailsPermittingType,
  ): void => {
    setFormValue(permittingType);
    setVisibleModal(!visibleModal);
    setModalType(ModalMainTypes.Edit);
  };

  const selectRowForDelete = (
    permittingType: JurisdictionDetailsPermittingType,
  ): void => {
    setFormValue(permittingType);
    setDeleteModalVisible(!deleteModalVisible);
  };

  const onDelete = async (): Promise<void> => {
    if (formValue.generatedId) {
      dispatch(
        PermittingActions.permittingRemovePermittingType.done({
          permittingTypeId: formValue.generatedId,
          permittingId,
        }),
      );
    }

    setDeleteModalVisible(!deleteModalVisible);
  };

  return (
    <div className="permit-type-table">
      <CustomTable
        columns={getPermitTypeTableColumns(
          selectRowForEdit,
          selectRowForDelete,
          showPermitExpireDate,
          !!permittingId && canBeEditable(tableAccess, isEditing),
        )}
        dataSource={currentPermitTypeTableData}
        pagination={false}
        hoverable={false}
      />
      <PermitTypeTableModal
        permittingId={permittingId}
        visibleModal={visibleModal}
        toggleModal={(): void => setVisibleModal(!visibleModal)}
        formValue={formValue}
        modalType={modalType}
      />
      <DeleteModal
        onOk={onDelete}
        onCancel={(): void => setDeleteModalVisible(!deleteModalVisible)}
        visible={deleteModalVisible}
      />
      {!!permittingId && canBeEditable(tableAccess, isEditing) && (
        <PrimaryButton
          icon="+"
          title="Add Type"
          className="permit-type-table__add-button"
          onClick={openAddModal}
        />
      )}
    </div>
  );
};
