import {
  adminDropdownValuesTabs,
  adminNotificationTabs,
  adminPaceTaskTabs,
} from '@client/pages/admin/admin.routing';
import { equipmentManagementTabs } from '@client/pages/equipment/equipment.routing';
import {
  allNotificationsTab,
  assignedToMeTab,
} from '@client/pages/home/home.routing';
import { projectDetailsSummaryTab } from '@client/pages/project/project.routing';
import { siteTabs } from '@client/pages/site/site.routing';
import { Tab } from '@models/classes';
import {
  engineeringSiteAuditTab,
  scopingSiteAuditTab,
  siteAuditDetailsRoute,
} from '@modules/client/pages/engineering/engineering.routing';
import { permittingJurisdictionTabs } from '@modules/client/pages/permitting/permitting.routing';

const regPath = (path: string): RegExp => {
  const regexp = path
    .replaceAll('/', '\\/')
    .replaceAll(/(:[\w\d]+)/g, '[^\\/]+');

  return new RegExp(`${regexp}$`);
};

export const URL_REG_EXPS: RegExp[] = [
  regPath(projectDetailsSummaryTab.path),
  regPath(engineeringSiteAuditTab.path),
  regPath(scopingSiteAuditTab.path),
  regPath(allNotificationsTab.path),
  regPath(assignedToMeTab.path),
  regPath(siteAuditDetailsRoute.path),
  ...equipmentManagementTabs.map((tab: Tab) => regPath(tab.path)),
  ...siteTabs.map((tab: Tab) => regPath(tab.path)),
  ...adminPaceTaskTabs.map((tab: Tab) => regPath(tab.path)),
  ...adminNotificationTabs.map((tab: Tab) => regPath(tab.path)),
  ...adminDropdownValuesTabs.map((tab: Tab) => regPath(tab.path)),
  ...permittingJurisdictionTabs.map((tab: Tab) => regPath(tab.path)),
];
