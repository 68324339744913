import { Store } from 'antd/lib/form/interface';
import { formDiffs, momentizeObjectDates } from '@core/utils/methods';
import {
  JurisdictionDetailsPermittingType,
  PermittingDetailsJurisdictionInfo,
  PermittingDetailsJurisdictionPermitting,
  PermittingDetailsJurisdictionZoning,
  PermittingJurisdictionAuditBody,
} from '@models/interfaces';

export const dataToBodyMapper = (
  [initialSummary, initialZoningFormData, initialPermitting]: Store[],
  [summary, zoningFormData, permitting]: Store[],
  jurisdictionId: string,
  permitTypeTableData: JurisdictionDetailsPermittingType[],
  zoningId?: string,
  permittingId?: string,
): PermittingJurisdictionAuditBody => {
  const summarySectionDifference = {
    ...formDiffs(initialSummary, summary),
    id: jurisdictionId,
  };

  return {
    summarySection:
      'jurisdictionAuditComplete' in summarySectionDifference
        ? (momentizeObjectDates(
            summarySectionDifference,
            ['jurisdictionAuditComplete'],
            true,
          ) as unknown as PermittingDetailsJurisdictionInfo)
        : (summarySectionDifference as unknown as PermittingDetailsJurisdictionInfo),
    ...(zoningId
      ? {
          zoningSection: {
            ...formDiffs(initialZoningFormData, zoningFormData),
            id: zoningId,
          } as PermittingDetailsJurisdictionZoning,
        }
      : {}),
    ...(permittingId
      ? {
          permittingSection: {
            ...formDiffs(initialPermitting, permitting),
            id: permittingId,
            permitTypeTableData,
          } as PermittingDetailsJurisdictionPermitting,
        }
      : {}),
  };
};
