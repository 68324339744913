import React, { FC, PropsWithChildren, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Form } from 'antd';
import {
  arraySort,
  canBeEditable,
  momentizeObjectDates,
} from '@core/utils/methods';
import {
  JURISDICTION_DATES_FOR_MOMENTIZE,
  YES_NO_GROUP,
} from '@models/constants';
import { PermittingDetailsJurisdictionZoning } from '@models/interfaces';
import { Panel } from '@shared/components';
import {
  Autocomplete,
  Checkbox,
  DatePicker,
  Input,
  RadioGroup,
  ShowMoreInput,
} from '@shared/modules';
import { DDVSelectors } from '@store/selectors';
import { JurisdictionAuditZoningSectionProps } from '../../models';

export const JurisdictionAuditZoningSection: FC<
  JurisdictionAuditZoningSectionProps
> = ({
  isEditing,
  jurisdictionAuditZoningFields: {
    zoningRequired,
    isZoningReqBasedOnProjectScope,
    reasonForZoningReqChange,
  },
  onValuesChange,
  data,
  form,
  hasZoning,
}: PropsWithChildren<JurisdictionAuditZoningSectionProps>) => {
  const { ZoningRequiredDDV, EstimatedZoningApprovalTimelineDDV } = useSelector(
    DDVSelectors.getDDVValuesAsOptionsByTypes([
      'ZoningRequired',
      'EstimatedZoningApprovalTimeline',
    ]),
  );

  useEffect(() => {
    if (data) {
      const {
        applicationRequirement,
        specialLicensingOrBondingCondition,
        describeOverallProcess,
        jurisdictionZoningNote,
        ...restData
      } = data;

      form?.setFieldsValue(
        momentizeObjectDates(
          restData as PermittingDetailsJurisdictionZoning,
          JURISDICTION_DATES_FOR_MOMENTIZE,
        ),
      );
    }
  }, [isEditing]);

  return (
    <Panel
      header="Zoning"
      className="prov-permitting-jurisdiction-audit__section"
      collapsible={hasZoning}
      restrictionText="Jurisdiction does not have Zoning for Project's Type and Subtype"
    >
      <Form
        className="prov-permitting-jurisdiction-audit__section__form"
        form={form}
        labelCol={{ span: 10 }}
        onValuesChange={onValuesChange}
      >
        <Autocomplete
          id="zoningRequired"
          className="left"
          label="Zoning Required"
          options={arraySort(ZoningRequiredDDV, 'DESC', 'viewValue')}
          isEditing={canBeEditable(zoningRequired, isEditing)}
        />
        <ShowMoreInput
          id="applicationRequirement"
          className="right"
          label="Application Requirements"
          data={data?.applicationRequirement}
          form={form}
          isEditing={false}
        />
        <Checkbox
          id="isZoningReqBasedOnProjectScope"
          className="left"
          label="Zoning Req Based On Project Scope"
          isEditing={canBeEditable(isZoningReqBasedOnProjectScope, isEditing)}
        />
        <Input
          id="towerHeightRestriction"
          className="right"
          label="Tower Height Restrictions"
          isEditing={false}
        />
        <Input
          id="reasonForZoningReqChange"
          className="left"
          label="Reason For Zoning Req Change"
          isEditing={canBeEditable(reasonForZoningReqChange, isEditing)}
        />
        <Input
          id="separationRequirement"
          className="right"
          label="Separation Requirements"
          isEditing={false}
        />
        <RadioGroup
          id="isWrittenDocumentationFromJurisdiction"
          className="left"
          label="Written Documentation from Jurisdiction"
          isEditing={false}
          buttons={YES_NO_GROUP}
        />
        <ShowMoreInput
          id="specialLicensingOrBondingCondition"
          className="right"
          label="Special Licensing/Bonding Conditions"
          data={data?.specialLicensingOrBondingCondition}
          form={form}
          isEditing={false}
        />
        <ShowMoreInput
          id="describeOverallProcess"
          className="left"
          label="Describe Overall Process"
          data={data?.describeOverallProcess}
          form={form}
          isEditing={false}
        />
        <Input
          id="otherFee"
          className="right"
          label="Other Fees"
          isEditing={false}
        />
        <Autocomplete
          id="estimatedZoningApprovalTimeline"
          className="left"
          label="Estimated Zoning Approval Timeline"
          options={EstimatedZoningApprovalTimelineDDV}
          isEditing={false}
        />
        <ShowMoreInput
          id="jurisdictionZoningNote"
          className="right"
          label="Zoning Notes"
          data={data?.jurisdictionZoningNote}
          form={form}
          isEditing={false}
        />
        <DatePicker
          id="jurisdictionReviewDate"
          className="right"
          label="Jurisdiction Review Date"
          isEditing={false}
        />
      </Form>
    </Panel>
  );
};
