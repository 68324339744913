import React, { FC, PropsWithChildren, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Divider, Form } from 'antd';
import { canBeEditable, momentizeObjectDates } from '@core/utils/methods';
import { ACTUAL_DATE } from '@core/utils/validators';
import { PermittingDetailsJurisdictionPermitting } from '@models/interfaces';
import { FormProps } from '@models/interfaces/form-props';
import { Panel, PermitTypeTable } from '@shared/components';
import { Autocomplete, DatePicker, ShowMoreInput } from '@shared/modules';
import { PermittingSelectors } from '@store/selectors';
import { PERMITTING_INFO_DATES, PermittingSection } from '../../models';

import './styles.scss';

type WorkflowPermittingSectionProps = FormProps & {
  permitting?: PermittingDetailsJurisdictionPermitting;
};

export const WorkflowPermittingSection: FC<WorkflowPermittingSectionProps> = ({
  isEditing,
  permissions: {
    e911AddressValidation,
    jurisdictionAuditComplete,
    prelimCDsReviewedPermitting,
    projectPermittingNote: projectPermittingNotePermission,
    tableAccess,
    permittingAgent,
  },
  data: originalData,
  form,
  onValuesChange,
}: PropsWithChildren<WorkflowPermittingSectionProps>) => {
  const { permittingUsers } = useSelector(
    PermittingSelectors.getPermittingDetailsData(['permittingUsers']),
  );
  const { projectPermittingNote = '', ...data } = originalData || {};

  const inactivePermittingUsers = useSelector(
    PermittingSelectors.getInactivePermittingUsers,
  );

  const inactiveAgent = inactivePermittingUsers?.find(
    user => user.value === data?.permittingAgent,
  );

  useEffect(() => {
    if (originalData) {
      form?.setFieldsValue(
        momentizeObjectDates<PermittingSection>(data, PERMITTING_INFO_DATES),
      );
    }
  }, []);

  return (
    <Panel
      header="Permitting"
      className="prov-workflow-permitting-section"
      collapsible
    >
      <Form
        className="prov-workflow-permitting-section__form"
        form={form}
        onValuesChange={onValuesChange}
      >
        <DatePicker
          id="jurisdictionAuditComplete"
          label="Jurisdiction Audit Complete"
          isEditing={canBeEditable(jurisdictionAuditComplete, isEditing)}
          formItemProps={{
            rules: ACTUAL_DATE(data?.jurisdictionAuditComplete),
          }}
        />
        <Autocomplete
          id="permittingAgent"
          label="Permitting Agent"
          options={[
            ...(permittingUsers || []),
            ...(inactiveAgent ? [inactiveAgent] : []),
          ]}
          isEditing={canBeEditable(permittingAgent, isEditing)}
          elementProps={{ allowClear: true }}
        />
        <DatePicker
          id="e911AddressValidation"
          label="E911 Address Validation Date"
          isEditing={canBeEditable(e911AddressValidation, isEditing)}
          formItemProps={{
            rules: ACTUAL_DATE(data?.e911AddressValidation),
          }}
        />
        <DatePicker
          id="prelimCDsReviewedPermitting"
          label="Prelim CDs Reviewed"
          isEditing={canBeEditable(prelimCDsReviewedPermitting, isEditing)}
          formItemProps={{
            rules: ACTUAL_DATE(data?.prelimCDsReviewedPermitting),
          }}
        />
        <ShowMoreInput
          id="projectPermittingNote"
          label="Project Permitting Notes"
          data={projectPermittingNote}
          form={form}
          isEditing={canBeEditable(projectPermittingNotePermission, isEditing)}
        />
      </Form>
      <Divider />
      <PermitTypeTable
        isEditing={isEditing}
        tableAccess={tableAccess}
        permittingId={data?.id}
        showPermitExpireDate={false}
      />
    </Panel>
  );
};
