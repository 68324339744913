import { Tab } from '@models/classes';
import { Dashboard } from './content';
import { DashboardProps } from './models';

export const allNotificationsTab = new Tab<Pick<DashboardProps, 'type'>>({
  component: Dashboard,
  name: 'All Notifications',
  path: 'all notifications',
  componentProps: {
    type: 'all',
  },
});

export const assignedToMeTab = new Tab<Pick<DashboardProps, 'type'>>({
  component: Dashboard,
  name: 'Assigned to me',
  path: 'assigned to me',
  componentProps: {
    type: 'assigned',
  },
});
